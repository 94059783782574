<style lang="scss">
.recurrence-audits {
	.v-expansion-panel-header--active {
		min-height: 48px;
	}
	.v-expansion-panel-header__icon {
		margin-left: 4px;
	}
	.audit-panel {
		border: 1px solid rgba(0, 0, 0, 0);
		&.active-panel {
			border: 1px solid rgba(0, 0, 0, 0.12);
		}
	}
	.audit-content {
		hr {
			margin: 8px 0;
			border-color: rgba(0, 0, 0, 0.12);
			border-bottom-width: 0;
		}
		ul {
			padding-left: 0;
			li {
				list-style: none;
			}
		}
	}
}
</style>

<template>
	<div>
		<v-container>
			<v-row>
				<v-col>
					<ScheduledGiftsToolbar />
				</v-col>
			</v-row>
			<template v-if="recurrenceStore.state.filteredRecurrences.length > 0">
				<v-row v-for="recurrence in recurrenceStore.state.filteredRecurrences" :key="recurrence.recurrenceId">
					<v-col class="pt-0">
						<v-divider class="mb-3"></v-divider>
						<div class="d-flex justify-space-between align-start">
							<h5>
								{{ getRecurrenceHeader(recurrence) }}
								<r-chip v-if="recurrence.status === 'OnHold'" class="ml-1" small color="warning">On Hold</r-chip>
								<r-chip v-if="recurrence.status === 'Deactivated'" class="ml-1" small color="warning">Canceled</r-chip>
								<r-chip v-if="recurrence.status === 'Complete'" class="ml-1" small color="success">Completed</r-chip>
							</h5>
							<r-actions v-if="canUpdateRecurrence(recurrence)">
								<r-action-item @click="editRecurrence(recurrence)">{{ recurrence.status === 'OnHold' ? 'Reactivate' : 'Edit' }}</r-action-item>
								<r-action-item v-if="recurrence.status === 'Active'" @click="recurrenceToPlaceOnHold = recurrence">Place On Hold</r-action-item>
								<r-action-item @click="recurrenceToCancel = recurrence">Cancel</r-action-item>
							</r-actions>
						</div>
						<div class="ml-5 mt-2">
							<div v-if="recurrence.recurringGiftDetails">
								<div v-for="(detail, index) in recurrence.recurringGiftDetails" :key="index">
									<span v-if="recurrence.recurringGiftDetails.length > 1">{{ detail.amount | dollarAmount }} to </span>
									<span>{{ detail.fund | fundName }}</span>
								</div>
							</div>
							<div class="text--secondary mt-2">
								<div>{{ getRecurrenceFrequencyDescription(recurrence) }}</div>
								<div v-if="recurrence.lastPaymentDate">Last gift on {{ recurrence.lastPaymentDate | date }}</div>
								<div v-if="getRemainingCount(recurrence)">
									{{ getRemainingCount(recurrence) }} {{ getRemainingCount(recurrence) > 1 ? 'gifts' : 'gift' }} remaining
									<span v-if="recurrence.recurrenceData.stopDate">ending on {{ recurrence.recurrenceData.stopDate | date }}</span>
								</div>
								<div v-if="recurrence.paymentMethod">{{ recurrence.paymentMethod.description }}</div>
								<div v-if="recurrence.dateDeactivated">Canceled on {{ recurrence.dateDeactivated | date }}</div>
							</div>
						</div>
						<v-expansion-panels v-if="recurrence.audits && recurrence.audits.length > 0" class="recurrence-audits mt-3" flat focusable tile>
							<v-expansion-panel class="audit-panel" active-class="active-panel">
								<v-expansion-panel-header class="d-flex justify-end">
									{{ getAuditHeaderText(recurrence) }}
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									<r-table :headers="getAuditTableHeaders(recurrence)" :items="recurrence.audits" :init-sort="{ descending: false, sortBy: '' }">
										<template slot-scope="{ items }">
											<tr v-for="item in items" :key="item.auditId" class="vertical-align-top">
												<td class="py-2">{{ item.dateCreated | date(true, true) }}</td>
												<td class="py-2">{{ item.updatedByIndividual }}</td>
												<td class="audit-content py-2" v-html="item.before"></td>
												<td class="audit-content py-2" v-html="item.after"></td>
												<td class="py-2">{{ item.reason }}</td>
											</tr>
										</template>
									</r-table>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-col>
				</v-row>
			</template>
			<template v-else>
				<div class="text-center mt-4">
					<h5 class="text-bold mb-2">There are no recurring gifts to display.</h5>
					<p>Whenever a gift is scheduled, you will see a summary of its recurrences here.</p>
				</div>
			</template>
		</v-container>
		<r-dialog v-model="showConfirmDialog" max-width="600px" type="warning">
			<template v-slot:title>{{ recurrenceToCancel ? 'Cancel Scheduled Gift' : 'Place Scheduled Gift on Hold' }}</template>
			<p class="pt-4">Are you sure you want to {{ recurrenceToCancel ? 'cancel this scheduled gift' : 'place this scheduled gift on hold' }}?</p>
			<r-form v-if="isAdminManaging" ref="reasonInput">
				<p>To proceed, please enter the reason for {{ recurrenceToCancel ? 'canceling this gift' : 'placing this gift on hold' }}:</p>
				<r-text-input v-model="reasonToUpdate" :label="recurrenceToCancel ? 'Cancel Scheduled Gift Reason' : 'Place on Hold Reason'" maxlength="255" type="text" required outlined />
			</r-form>
			<template v-slot:actions>
				<r-btn color="warning" @click="submitRecurrenceUpdate">Confirm</r-btn>
				<r-btn class="ml-4" text @click="showConfirmDialog = false">Close</r-btn>
			</template>
		</r-dialog>
	</div>
</template>

<script>
import formatDollars from '@/filters/giving/Dollars';
import formatOrdinal from '@/filters/Ordinal';
import moment from 'moment';
import ScheduledGiftsToolbar from '@/components/giving/scheduledGifts/ScheduledGiftsToolbar.vue';

export default {
	components: {
		ScheduledGiftsToolbar
	},
	filters: {
		dollarAmount: function(amount) {
			return formatDollars(amount);
		},
		fundName: function(fund) {
			return fund ? fund.displayName || fund.name : '--';
		},
		date: function(dateTime, isUTC = false, includeTime = false) {
			const date = isUTC ? moment.utc(dateTime).local() : moment(dateTime);
			const format = includeTime ? 'MMM D, YYYY h:mm a' : 'MMM D, YYYY';
			return date.format(format);
		}
	},
	data() {
		return {
			recurrenceStore: _REALM.STORE.recurrenceStore,
			recurrenceToCancel: null,
			recurrenceToPlaceOnHold: null,
			reasonToUpdate: ''
		};
	},
	computed: {
		showConfirmDialog: {
			get: function() {
				return this.recurrenceToCancel !== null || this.recurrenceToPlaceOnHold !== null;
			},
			set: function(newValue) {
				if (newValue === false) {
					this.recurrenceToCancel = null;
					this.recurrenceToPlaceOnHold = null;
					this.reasonToUpdate = '';
				}
			}
		},
		isAdminManaging() {
			return _REALM.STORE.recurrenceStore.state.recurrenceFilters.individualId !== _REALM.STORE.accountStore.state.authAccount.individualId;
		}
	},
	methods: {
		getRecurrenceHeader(recurrence) {
			let header = this.getRecurrenceTotal(recurrence) + ' gift';
			if (recurrence.status === 'Active') {
				const nextDate = this.$options.filters.date(recurrence.nextPaymentDate);
				header += ' scheduled for ' + nextDate;
			}

			return header;
		},
		getRecurrenceTotal(recurrence) {
			let total = 0;
			const details = recurrence.recurringGiftDetails || [];

			for (const detail of details) {
				total += detail.amount;
			}

			return formatDollars(total);
		},
		getRecurrenceFrequencyDescription(recurrence) {
			let frequency = this.recurrenceStore.getRecurrenceFrequencyText(recurrence);
			if (recurrence.recurrenceType === 'Weekly') {
				frequency += ` on ${recurrence.recurrenceData.dayOfWeek}s`;
			} else if (recurrence.recurrenceType === 'Monthly') {
				frequency += ' on the ' + formatOrdinal(recurrence.recurrenceData.day1);
				if (recurrence.recurrenceData.day2) {
					frequency += ' and the ' + formatOrdinal(recurrence.recurrenceData.day2);
				}
			}

			return frequency;
		},
		getRemainingCount(recurrence) {
			if (recurrence.recurrenceType === 'Once' || !recurrence.totalCount) {
				return null;
			}

			return recurrence.totalCount - recurrence.currentCount;
		},
		getAuditHeaderText(recurrence) {
			const audit = recurrence.audits[0];
			const type = audit.type === 'AddRecurring' ? 'Added' : 'Edited';
			const date = this.$options.filters.date(audit.dateCreated, true);
			return `${type} by ${audit.updatedByIndividual} on ${date}`;
		},
		getAuditTableHeaders(recurrence) {
			const audit = recurrence.audits[0];
			const typeHeader = audit.type === 'AddRecurring' ? 'Added by' : 'Edited by';
			return [
				{ text: 'Date/Time', sortable: false },
				{ text: typeHeader, sortable: false },
				{ text: 'Changed from', sortable: false },
				{ text: 'Changed to', sortable: false },
				{ text: 'Reason', sortable: false }
			];
		},
		canUpdateRecurrence(recurrence) {
			return (recurrence.status === 'Active' || recurrence.status === 'OnHold') && !recurrence.dateDeactivated && !recurrence.dateCompleted;
		},
		editRecurrence(recurrence) {
			const query = { ...this.$route.query, recurrenceId: recurrence.recurrenceId };
			_REALM.VM.$router.push({ name: 'donor-form', query });
		},
		async submitRecurrenceUpdate() {
			const reason = this.isAdminManaging ? this.reasonToUpdate : null;
			const isValid = this.isAdminManaging ? this.$refs.reasonInput.validate() : true;
			if (!isValid || !this.showConfirmDialog) {
				return;
			}

			try {
				_REALM.LOADER.show();
				if (this.recurrenceToCancel) {
					await this.recurrenceStore.cancelRecurrence(this.recurrenceToCancel.recurrenceId, reason);
					_REALM.TOAST.warning('Gift has been canceled.');
				} else {
					await this.recurrenceStore.placeRecurrenceOnHold(this.recurrenceToPlaceOnHold.recurrenceId, reason);
					_REALM.TOAST.warning('Gift has been placed on hold.');
				}

				this.showConfirmDialog = false;
			} finally {
				_REALM.LOADER.hide();
			}
		}
	}
};
</script>
