<template>
	<div>
		<r-alert v-for="notification in recurrenceStore.state.recurrenceNotifications" :key="notification.recurrenceId" :type="getAlertType(notification)" outlined text :dismissible="notification.id && notification.id.length > 0" @input="deleteNotification(notification)">
			<div style="color: black">{{ notification.message }}</div>
			<a v-if="canUpdateRecurrence(notification)" href="javascript:void(0);" @click="editRecurrence(notification)">Update Recurring Gift</a>
		</r-alert>
	</div>
</template>

<script>
export default {
	data() {
		return {
			recurrenceStore: _REALM.STORE.recurrenceStore
		};
	},
	methods: {
		getAlertType(notification) {
			const warningTypes = ['CardExpired', 'AchReturned', 'OnHold'];
			return warningTypes.includes(notification.messageType) ? 'warning' : 'info';
		},
		editRecurrence(notification) {
			const query = { ...this.$route.query, recurrenceId: notification.recurrenceId };
			_REALM.VM.$router.push({ name: 'donor-form', query });
		},
		deleteNotification(notification) {
			this.recurrenceStore.deleteRecurrenceNotifications(notification);
		},
		canUpdateRecurrence(notification) {
			const noUpdateStatus = ['Complete', 'Deactivated'];
			const recurrence = this.recurrenceStore.getRecurrence(notification.recurrenceId);
			return notification.targetId && recurrence !== null && !noUpdateStatus.includes(recurrence.status);
		}
	}
};
</script>
