<template>
	<v-layout v-if="loaded" row wrap justify-center>
		<v-col lg="9" xl="6">
			<r-card>
				<v-card-title>Scheduled Gifts</v-card-title>
				<ScheduledGiftsNotifications />
				<ScheduledGiftsGrid />
			</r-card>
		</v-col>
	</v-layout>
</template>

<script>
import ScheduledGiftsGrid from '@/components/giving/scheduledGifts/ScheduledGiftsGrid.vue';
import ScheduledGiftsNotifications from '@/components/giving/scheduledGifts/ScheduledGiftsNotifications.vue';
export default {
	components: {
		ScheduledGiftsNotifications,
		ScheduledGiftsGrid
	},
	data() {
		return {
			loaded: false
		};
	},
	mounted() {
		this.loadPageData();
	},
	methods: {
		loadPageData() {
			const self = this;
			_REALM.STORE.recurrenceStore.state.recurrenceFilters.individualId = self.$route.query.individualId || _REALM.STORE.accountStore.state.authAccount.individualId;

			const promises = [_REALM.STORE.recurrenceStore.getRecurrences(), _REALM.STORE.recurrenceStore.getRecurrenceNotifications()];

			_REALM.LOADER.show();
			return Promise.all(promises)
				.then(() => {
					_REALM.LOADER.hide();
					self.loaded = true;
				})
				.catch(e => {
					_REALM.ERRORHANDLER.redirectToErrorPage(e);
				});
		}
	}
};
</script>
