<style lang="scss">
@import '@/scss/variables.scss';
.panel {
	background-color: $realm-blue-lighter;
	border: 1px solid $realm-blue;
}
</style>

<template>
	<div>
		<div>
			<r-btn :color="showPanel ? 'primary' : 'default'" small @click="showPanel = !showPanel"><v-icon>mdi-filter</v-icon></r-btn>
			<r-btn v-if="appliedStatusFilterString" class="ml-3" color="default" small @click="showPanel = !showPanel">{{ appliedStatusFilterString }}</r-btn>
			<r-btn v-if="!defaultStatusFiltersAreApplied" class="ml-3" text small @click="resetFilters">Reset Filters</r-btn>
		</div>
		<v-expand-transition>
			<div v-show="showPanel">
				<div class="panel pa-4 rounded mt-3">
					<p>Recurrence Status</p>
					<v-checkbox v-for="item in statusTexts" :key="item.name" v-model="selectedFilters[item.status]" :label="item.text" class="mt-2" hide-details />
					<div class="mt-3">
						<r-btn small color="primary" @click="applySelectedFilters">Filter</r-btn>
						<r-btn class="ml-3" text small @click="resetFilters">Clear Filters</r-btn>
					</div>
				</div>
			</div>
		</v-expand-transition>
	</div>
</template>

<script>
export default {
	data() {
		return {
			showPanel: false,
			recurrenceStore: _REALM.STORE.recurrenceStore,
			selectedFilters: { ..._REALM.STORE.recurrenceStore.state.recurrenceFilters.statuses },
			statusTexts: [
				{ status: 'active', text: 'Active' },
				{ status: 'onHold', text: 'On Hold' },
				{ status: 'complete', text: 'Completed' },
				{ status: 'deactivated', text: 'Canceled' }
			]
		};
	},
	computed: {
		appliedStatusFilterString() {
			const appliedStatuses = Object.entries(this.recurrenceStore.state.recurrenceFilters.statuses)
				.filter(([status, isSelected]) => isSelected)
				.map(([status, isSelected]) => status);

			const statusTexts = [];
			for (const status of appliedStatuses) {
				const statusText = this.statusTexts.find(st => st.status === status);
				statusTexts.push(statusText.text);
			}

			return statusTexts.join(', ');
		},
		defaultStatusFiltersAreApplied() {
			let filtersAreDefault = true;
			const defaultStatusFilters = this.recurrenceStore.getDefaultStatusFilters();
			const appliedStatusFilters = this.recurrenceStore.state.recurrenceFilters.statuses;
			for (const statusName in defaultStatusFilters) {
				if (defaultStatusFilters[statusName] !== appliedStatusFilters[statusName]) {
					filtersAreDefault = false;
				}
			}

			return filtersAreDefault;
		}
	},
	methods: {
		resetFilters() {
			this.showPanel = false;
			this.recurrenceStore.resetStatusFilters();
			this.selectAppliedFilters();
		},
		applySelectedFilters() {
			this.showPanel = false;
			this.recurrenceStore.applyStatusFilters(this.selectedFilters);
		},
		selectAppliedFilters() {
			for (const [key, value] of Object.entries(this.recurrenceStore.state.recurrenceFilters.statuses)) {
				this.selectedFilters[key] = value;
			}
		}
	}
};
</script>
